#container {
  height: 100%;
  min-height: 100vh;
  color: #fff;
  background: linear-gradient(-45deg, #8e2de2, #4a00e0, #f7971e, #ffd200);
  background-size: 400% 400%;
  -webkit-animation: Gradient 15s ease infinite;
  -moz-animation: Gradient 15s ease infinite;
  animation: Gradient 15s ease infinite;
}

.shake {
  animation: shake-animation 4.72s ease infinite;
  transform-origin: 50% 50%;
}

@keyframes shake-animation {
  0% {
    transform: translate(0, 0);
  }
  1.78571% {
    transform: translate(5px, 0);
  }
  3.57143% {
    transform: translate(0, 0);
  }
  5.35714% {
    transform: translate(5px, 0);
  }
  7.14286% {
    transform: translate(0, 0);
  }
  8.92857% {
    transform: translate(5px, 0);
  }
  10.71429% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

.profile {
  padding-top: 2rem;
}

.image-lays {
  height: 170px;
  width: 170px;
  padding-top: 2.5rem;
  background-position: top;
  margin: 0px auto;
  background-repeat: no-repeat;
  border-radius: 14rem;
  background-size: cover;
  background-color: white;
  background-image: url("./assets/images/lays.png");
}

.image-lays img {
  width: 170px;
}

.wrapper-links {
  display: flex;
  justify-content: center;
  color: white;
  flex-direction: column;
  align-items: center;
  width: 60%;
  margin: 2rem auto;
}

.wrapper-links h2 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.wrapper-links .link {
  color: white;
  font-weight: bold;
  text-decoration: none;
  padding: 0.8rem;
  border: 1px solid white;
  width: 100%;
  text-align: center;
  font-size: 14px;
  border-radius: 8px;
}

.wrapper-links .link:not(:last-child) {
  margin-bottom: 2rem;
}

.wrapper-links .link:hover {
  background-color: white;
  color: black;
}

.footer-logo {
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;
}

.footer-logo img {
  width: 170px;
}

@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@-moz-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
